/*******************************
         Site Overrides
*******************************/

/*******************************
           Variations
*******************************/

/*-------------------
       Colors
--------------------*/

/* Basic */
.ui.basic.red.label {
  color: #9f3a38 !important;
  border-color: #9f3a38 !important;
}
