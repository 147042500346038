/*******************************
         Site Overrides
*******************************/

/*-------------------
        Basic
--------------------*/

.ui.basic.button {
  background: white !important;
}

/*******************************
           Variations
*******************************/

/*--------------
 Basic Circular
---------------*/

.ui.basic.circular.icon.button {
  box-shadow: 0px 1px 3px 0px #a1a1a1;
}
