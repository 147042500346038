.ui.selection.dropdown {
  margin: 1px 0;
}

.ui.selection.active.dropdown {
  border-width: 2px;
  margin: 0;
  padding-left: calc(1em - 1px);
}

.ui.selection.dropdown > .dropdown.icon:before {
  content: '';
  display: inline-block;
  width: 1em;
  height: 1em;
  background-color: @blue !important;
  mask-image: url(../../../images/chevron-down.svg);
  -webkit-mask-image: url(../../../images/chevron-down.svg);
  mask-position: center;
  -webkit-mask-position: center;
  mask-size: cover;
  -webkit-mask-size: cover;
}

.ui.selection.active.dropdown > .dropdown.icon:before {
  mask-image: url(../../../images/chevron-up.svg);
}
