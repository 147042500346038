/*******************************
         Site Overrides
*******************************/

/*---------------------
   Secondary Pointing
-----------------------*/

.ui.secondary.pointing.menu .item {
  margin: 0em @relativeSmall;
}

/* Pressed */
.ui.secondary.pointing.menu .dropdown.item:active,
.ui.secondary.pointing.menu .link.item:active,
.ui.secondary.pointing.menu a.item:active {
  border-color: transparent;
}

/*--------------
    Dropdowns
---------------*/

/* Secondary */
.ui.secondary.menu .dropdown.item > .menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: 1px;
}
