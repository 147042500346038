/*******************************
         Site Overrides
*******************************/

/*--------------
      Basic
---------------*/

.ui.basic.table td {
  border-top: 1px solid @blue;
}
